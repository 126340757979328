import apiClient from "./apiClient";
import { SIGNIN_URL } from "./urlApi";

const signup = ({ email, first_name, last_name, password1, password2 }) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("password1", password1);
  formData.append("password2", password2);
  return apiClient.post(SIGNIN_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const signin = ({ email, password }) => {
  return apiClient.post(`/signin/`, { email, password });
};


const authAPI = {
  signup,
  signin,
};

export default authAPI;
