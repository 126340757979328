
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';

//pang
import threeDotImg from '../../assets/png/three-dots.png';
import logoImg from '../../assets/png/ford-logo.png';


//Icons
export const LogoIcon = Logo;

//Image
export const ThreeDotImg = () => <img src={threeDotImg}/>;
export const LogoImg = () => <img src={logoImg}/>;
