import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import apiClient from "../../api/apiClient";
import authAPI from "../../api/authAPI";
import {
  localAuthenticate,
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from "../../utils/localAuth";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const initialState = {
  isSignedIn: getAccessToken() ? true : false,
  isFetching: false,
  // isSuccess: false,
  isError: false,
  errorMessage: "",
  menu: {},
};

export const signinUser = createAsyncThunk(
  "auth/signin",
  async (params, thunkAPI) => {
    const { email, password, onComplete } = params;

    return authAPI.signinFake({ email, password })
    .then(({ data, status }) => {
      if (status === 200) {
        setAccessToken(data.token);
        thunkAPI.dispatch(authSlice.actions.signIn());
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    })

    return authAPI
      .signin({ email, password })
      .then(({ data, status }) => {
        if (status === 200) {
          setAccessToken(data.token);
          thunkAPI.dispatch(authSlice.actions.signIn());
          // onComplete(null, data);
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      })
      .catch((error) => {
        // onComplete(error.response.data, null);
        //console.log(email);
        console.log(error.response.data);
        // if (password == "12345") {
        //   setAccessToken("token_test_12345");
        //   thunkAPI.dispatch(authSlice.actions.signIn());
        //   history.push("/dashboard");
        // }
        thunkAPI.rejectWithValue(error.response.data);
        throw new Error(error.response.data);
      });
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isSignedIn = true;
    },
    signOut: (state) => {
      removeAccessToken();
      // state = { ...state, isSignedIn: false };
      state.isSignedIn = false;
      history.push("/");
    },
    setUser: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: {
    [signinUser.fulfilled]: (state, action) => {
      // state.email = payload.email;
      // state.username = action.payload.name;
      state.isFetching = false;
      // state.isSuccess = true;
      // state.isSignedIn = true;
      return state;
    },
    [signinUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signinUser.rejected]: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error;
    },
  },
});

const { actions, reducer } = authSlice;
export const { signIn, signOut, setUser } = actions;

export default reducer;


