import { createSlice } from "@reduxjs/toolkit";
import { trims, exteriorColors, interiorColors } from "../../constants/ui";

const checkImageStatus = imageUrl => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve('success');
    img.onerror = reject;
    img.src = imageUrl;
  });
}
const filterByTrim = trim => {
  const trimData = trims.find(ele => ele.id === trim);
  return trimData ? exteriorColors.filter(extColor => trimData.blockedExtColors.indexOf(extColor.id) < 0) : [];
}
const createConventionArray = (trim, extColor) => {
  var i, res = [], len = 8;
  for(i = 1 ; i <= len ; i ++) {
    const url = `${process.env.REACT_APP_AUTH_BASE_URL}/trims/maverick/2022/${trim}/${i < 6 ? "ext" : "int"}/a0${i}/${i < 6 ? "extfull" : "intfull"}_maverick_2022_${trim}_${extColor}_a0${i}.${process.env.REACT_APP_ASSET_EXTENSION}`.toLowerCase()
    // console.log('status', await checkImageStatus(url));
    res.push(url);
  }
  return res;
}
const initialState = {
  configre: {
    trims: {
      value: trims[0].id,
      data: trims,
    },
    exteriorColors: {
      value: exteriorColors[1].id,
      data: filterByTrim(trims[0].id),
    },
    // exteriorColors: createValidExteriorColors(trims[0].id),
    interiorColors: {
      value: interiorColors[0].id,
      data: interiorColors.slice(0,1),
    },
  },
  slider: {
    data: createConventionArray(trims[0].id, exteriorColors[1].id),
  }
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTrim: (state, action) => {
      state.configre.trims.value = action.payload;
      
      const findInterioColors = interiorColors.filter((item) => item.trim === action.payload);
      state.configre.interiorColors.data = findInterioColors;
      state.configre.interiorColors.value = (findInterioColors && findInterioColors[0]?.id) || null;

      state.configre.exteriorColors.data = filterByTrim(state.configre.trims.value);
      state.configre.exteriorColors.value = state.configre.exteriorColors.data[0].id;
      state.slider.data = createConventionArray(state.configre.trims.value, state.configre.exteriorColors.value);
    },
    setExteriorColor: (state, action) => {
      state.configre.exteriorColors.value = action.payload;
      state.slider.data = createConventionArray(state.configre.trims.value, state.configre.exteriorColors.value);
    },
    setInteriorColor: (state, action) => {
      state.configre.interiorColors.value = action.payload;
    },
    changeUI: (state, action) => {
      state = action.payload;
    },
  },
});

const { actions, reducer } = uiSlice;
export const {
  setTrim,
  setPackage,
  setExteriorColor,
  setInteriorColor,
  changeUI,
} = actions;

export default reducer;
